import { ChangeEvent, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import {
  AlignmentHorizontalOption,
  ISpacing,
  IThemeState,
  TButtonId,
  TButtonReadoutValue,
  TInputId,
  TInputReadoutValue,
  TSnackbarReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxAddToCartQuantityB2BProps,
  IBoxAddToCartQuantityB2BSettings,
} from "../../../../boxes/BoxAddToCartB2B/BoxAddToCartQuantityB2B.types";
import { IBoxSearchResultsB2BProps } from "../../../../boxes/BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import { IBoxCartB2BProps } from "../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import { useTemporaryErrorBySnackbar } from "../../../../helpers/useTemporaryErrorBySnackbar";
import { useCommunicationBetweenBoxesContext } from "../../../../structure/Contexts/CommunicationBetweenBoxes";
import { useBoxContext } from "../../../../structure/Contexts/BoxContext";
import StyledButton from "../../../styleElements/StyledButton/StyledButton";
import { StyledInput } from "../../Input/StyledInput/StyledInput";
import { useOpenPortalSnackbar } from "../../Snackbar/Snackbar";
import { StyledChangeProductQuantityB2B } from "./StyledChangeProductQuantityB2B.styled";
import {
  findCartLineBySku,
  getProductType,
  quantityRounding,
  stockDisplaying,
} from "./cartB2B.methods";
import { useCartB2BContext } from "./useCartB2BContext";
import {
  eventAddToCart,
  eventRemoveFromCart,
} from "../../../../boxes/analytics/events";
import { useTheme } from "styled-components";
import useSetSessionStorage from "../../../../shared/hooks/useSetSessionStorage";
import { boxTypeToEventOrigin } from "../../../../boxes/analytics/helpers";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";

type CartProps = {
  product: {
    sku: string;
    deliveryQuantity?: number;
    unitOfMeasure?: {
      currentUnitOfMeasure: string;
      availableUnitsOfMeasure: {
        unitOfMeasure: string;
        unitOfMeasureLabel: string;
        unitOfMeasureQuantity: number;
      }[];
    };
    productPriceDetails?: { netValue: number; grossValue: number };
    price?: number;
    suggestedRetailPrice?: number;
    concession?: boolean;
  };
  isCart: true;
};

type SRPProps = {
  product: {
    sku: string;
    deliveryQuantity: number;
    unitOfMeasure: {
      currentUnitOfMeasure: string;
      availableUnitsOfMeasure: {
        unitOfMeasure: string;
        unitOfMeasureLabel: string;
        unitOfMeasureQuantity: number;
      }[];
    };
    productPriceDetails: { netValue: number; grossValue: number };
    price: number;
    suggestedRetailPrice: number;
    concession: boolean;
  };
  isCart: false;
};
export type IChangeProductQuantityProps = {
  minusButtonQuantityStyle: TButtonReadoutValue | TButtonId;
  plusButtonQuantityStyle: TButtonReadoutValue | TButtonId;
  inputQuantityStyle: TInputReadoutValue | TInputId;
  changeProductQuantityMinusLabel?: string;
  changeProductQuantityPlusLabel?: string;
  disabledInput?: boolean;
  spaceBetweenInputButtons?: number;
  padding?: ISpacing;
  alignment?: AlignmentHorizontalOption;
  snackbarValues?: {
    successSnackbarStyle: `snackbar$$${number}` | TSnackbarReadoutValue;
    successSnackbarText: string;
    errorSnackbarStyle: `snackbar$$${number}` | TSnackbarReadoutValue;
    errorSnackbarText: string;
  };
} & (CartProps | SRPProps);

const ChangeProductQuantityB2B: React.FC<IChangeProductQuantityProps> = (
  props
) => {
  const {
    product,
    inputQuantityStyle,
    minusButtonQuantityStyle,
    plusButtonQuantityStyle,
    disabledInput,
    changeProductQuantityMinusLabel = "-",
    changeProductQuantityPlusLabel = "+",
    spaceBetweenInputButtons = 10,
    isCart,
    padding,
    alignment,
    snackbarValues,
  } = props;

  const { currencyShort } = useLanguageCurrencyContext();
  const { getSessionStorageItem } = useSetSessionStorage();
  const customer_id =
    parseInt(getSessionStorageItem("customer_id") as string) || null;
  const { addToCart, deleteProduct, cartData, status } = useCartB2BContext();
  const timeoutRef = useRef<number | null>(null);
  const { quantity, deliveryQuantity, unitOfMeasure, stock } =
    findCartLineBySku(cartData, product);

  const productType = getProductType(cartData, product.sku);
  const { chosenUnit } = useCommunicationBetweenBoxesContext();

  const isError = status !== "OK" && status !== "LOADING";
  const concession = product.concession;

  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const [quantityState, setQuantityState] = useState(quantity);
  let prevQuantityState = quantity;
  useEffect(() => {
    setQuantityState(quantity);
  }, [quantity]);

  const [stockExceeded, setStockExceeded] = useState(false);

  const { messages, settings, boxType } = useBoxContext<
    IBoxCartB2BProps & IBoxAddToCartQuantityB2BProps & IBoxSearchResultsB2BProps
  >();
  const temporaryError = useTemporaryErrorBySnackbar(stockExceeded, () =>
    setStockExceeded(false)
  );

  const disableMinusButton = quantityState <= 0 || isError;

  const disablePlusButton =
    (stock !== null && quantity >= stock) || isError || concession === false;

  const disableInput = concession === false || disabledInput;

  const inputError =
    boxType === "B2B_ADD_TO_CART_QUANTITY" ? !temporaryError : undefined;

  const debouncedChangeProductQuantity = debounce(
    (quantity: number, prevQuantity?: number) => {
      if (cartData?.stockVerification && stock !== null && quantity > stock) {
        (boxType === "SEARCH_RESULTS" || boxType === "PRODUCT_SLIDER") &&
        snackbarValues !== undefined
          ? openPortalSnackbar(
              snackbarValues.errorSnackbarStyle,
              snackbarValues.errorSnackbarText
            )
          : openPortalSnackbar(
              settings.add_to_cart_error_snackbar,
              messages.product_error_stock_exceeded
            );
        setStockExceeded(true);
      } else if (quantity <= 0 && boxType !== undefined) {
        deleteProduct({ sku: product.sku, productType: productType ?? "" });
        eventRemoveFromCart({
          product_id: product.sku,
          customer_id: customer_id,
          quantity: prevQuantity ? prevQuantity - quantityState : quantityState,
          sales_price: product?.productPriceDetails?.grossValue,
          net_sales_price: product?.productPriceDetails?.netValue,
          base_price: product?.price,
          suggested_retail_price: product?.suggestedRetailPrice,
          currency: currencyShort,
          origin: boxTypeToEventOrigin(boxType),
        });
        setStockExceeded(false);
        setQuantityState(0);
        return;
      } else {
        setStockExceeded(false);
      }

      const newQuantity = quantityRounding(quantity, stock, deliveryQuantity);

      if (newQuantity > 0 && boxType !== undefined) {
        setQuantityState(newQuantity);
        addToCart({
          sku: product.sku,
          quantity: newQuantity,
          unitOfMeasure:
            unitOfMeasure.currentUnitOfMeasure ??
            product?.unitOfMeasure?.currentUnitOfMeasure,
          isCart,
        }).then(() => {
          if (newQuantity > (prevQuantity ? prevQuantity : quantityState)) {
            eventAddToCart({
              product_id: product.sku,
              customer_id: customer_id,
              quantity: newQuantity - (prevQuantity ?? quantityState),
              sales_price: product?.productPriceDetails?.grossValue,
              net_sales_price: product?.productPriceDetails?.netValue,
              base_price: product?.price,
              suggested_retail_price: product?.suggestedRetailPrice,
              currency: currencyShort,
              origin: boxTypeToEventOrigin(boxType),
            });
            if (
              (boxType === "SEARCH_RESULTS" || boxType === "PRODUCT_SLIDER") &&
              snackbarValues !== undefined
            ) {
              openPortalSnackbar(
                snackbarValues.successSnackbarStyle,
                snackbarValues.successSnackbarText
              );
            }
          }
          if (newQuantity < (prevQuantity ? prevQuantity : quantityState)) {
            eventRemoveFromCart({
              product_id: product.sku,
              customer_id: customer_id,
              quantity: Math.abs(quantityState - (prevQuantity ?? newQuantity)),
              sales_price: product?.productPriceDetails?.grossValue,
              net_sales_price: product?.productPriceDetails?.netValue,
              base_price: product?.price,
              suggested_retail_price: product?.suggestedRetailPrice,
              currency: currencyShort,
              origin: boxTypeToEventOrigin(boxType),
            });
          }
          setQuantityState(newQuantity);
        });
      }
    },
    500
  );

  const changeProductQuantity = (quantity: number, prevQuantity?: number) => {
    debouncedChangeProductQuantity(quantity, prevQuantity);
  };

  const currentPackageTypeCapacity =
    unitOfMeasure.availableUnitsOfMeasure?.find((pack) => {
      if (
        (boxType === "B2B_ADD_TO_CART_QUANTITY" &&
          settings.b2b_add_to_cart_unit === "true") ||
        boxType === "PRODUCT_SLIDER" ||
        boxType === "SEARCH_RESULTS"
      )
        return pack.unitOfMeasure === chosenUnit;
      else if (
        boxType === "B2B_ADD_TO_CART_QUANTITY" &&
        (settings as IBoxAddToCartQuantityB2BSettings).b2b_add_to_cart_unit ===
          "false"
      )
        return pack.unitOfMeasure === unitOfMeasure.baseUnitOfMeasure;
      else return pack.unitOfMeasure === unitOfMeasure.currentUnitOfMeasure;
    })?.unitOfMeasureQuantity ?? 1;

  const loseFocusAfterValueHasChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      e.target.blur();
    }, 3000);
  };

  return (
    <StyledChangeProductQuantityB2B
      inputStyle={inputQuantityStyle}
      spaceBetweenInputButtons={spaceBetweenInputButtons}
      boxType={boxType}
      padding={padding}
      alignment={alignment}
    >
      <StyledButton
        renderAs="button"
        $settings={minusButtonQuantityStyle}
        className={`change-product-quantity-b2b__minus-button ${
          disableMinusButton ? "disabled-button" : ""
        }`}
        onClick={() =>
          changeProductQuantity(
            quantityState -
              (currentPackageTypeCapacity === 1
                ? deliveryQuantity
                : currentPackageTypeCapacity)
          )
        }
        data-testid="decrementButton"
      >
        {changeProductQuantityMinusLabel}
      </StyledButton>
      <StyledInput
        className={"change-product-quantity-b2b__count-input"}
        $settings={inputQuantityStyle}
        value={stockDisplaying(quantityState, currentPackageTypeCapacity)}
        onChange={(e) => {
          prevQuantityState = quantityState;
          setQuantityState(~~e.target.value * currentPackageTypeCapacity);
          loseFocusAfterValueHasChanged(e);
        }}
        onBlur={(e) => {
          changeProductQuantity(quantityState, prevQuantityState);
        }}
        onKeyPress={(e) => {
          e.key === "Enter" &&
            changeProductQuantity(quantityState, prevQuantityState);
        }}
        data-testid="productCountInput"
        disabled={disableInput}
        formNoValidate={inputError}
      />
      <StyledButton
        renderAs="button"
        $settings={plusButtonQuantityStyle}
        className={`change-product-quantity-b2b__plus-button ${
          disablePlusButton ? "disabled-button" : ""
        }`}
        onClick={() => {
          changeProductQuantity(
            quantityState +
              (currentPackageTypeCapacity === 1
                ? deliveryQuantity
                : currentPackageTypeCapacity)
          );
        }}
        data-testid="incrementButton"
      >
        {changeProductQuantityPlusLabel}
      </StyledButton>
    </StyledChangeProductQuantityB2B>
  );
};

export default ChangeProductQuantityB2B;
